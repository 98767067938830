.label {
  @apply block mb-2 text-base capitalize text-brand-400;
}
.form-control {
  @apply bg-brand-900 p-3 rounded-sm outline-none w-full focus:ring-[3px] ring-brand-700 transition-all duration-500 placeholder:text-brand-500 placeholder:capitalize;
}
.input-file {
  @apply block file:py-2.5 file:px-4 file:rounded file:cursor-pointer file:bg-brand-600 file:text-white file:text-sm file:font-medium file:mr-4;
}
.form-label {
  @apply block mb-1.5 text-sm font-medium capitalize text-brand-700;
}
.form-input {
  @apply w-full border px-3 py-3 border-brand-300 rounded-md outline-none; 
}