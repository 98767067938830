.btn {
  @apply py-2 px-5 text-white rounded uppercase;
}
.btn-dark {
  @apply bg-brand-900 px-4 py-3 capitalize font-medium;
}
.btn-gray {
  @apply bg-brand-600 text-white capitalize font-medium px-4 py-3 cursor-pointer !important;
}
.btn-warning {
  @apply bg-orange-400 py-[6px];
}
.btn-danger {
  @apply bg-rose-500 py-[6px];
}
